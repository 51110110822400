<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo cursor-pointer" @click="$router.push('/')">
      <img src="@/assets/images/logo-mini.png" alt="ongilagroup" class="w-2rem mr-2" />
      <span class="text-2xl font-semibold">TRANSPORT</span>
    </div>
    <Button rounded icon="pi pi-bars" class="p-link layout-menu-button layout-topbar-button" @click="$emit('update:is_static', !is_static)">
      <i class="text-2xl pi pi-bars"></i>
    </Button>
    <Button rounded icon="pi pi-ellipsis-v" class="p-link layout-topbar-menu-button layout-topbar-button" @click="toggle">
      <i class="text-2xl pi pi-ellipsis-v"></i>
    </Button>
    <div class="layout-topbar-menu">
      <Button text rounded class="ml-2 p-2" @click="toggleLang">
        <span class="uppercase font-semibold text-xl">{{ profile.language_code }}</span>
      </Button>
      <Button text rounded icon="pi pi-user" severity="secondary" class="ml-2" @click="toggle">
        <i class="text-2xl pi pi-user"></i>
      </Button>
    </div>
  </div>

  <edit-password-dialog v-if="dialog_edit_password" @close="dialog_edit_password = false" />

  <OverlayPanel ref="op">
    <div class="flex align-items-center justify-content-between">
      <div class="flex align-items-center">
        <ong-avatar :item="profile" :size="40" />
        <div class="ml-2 flex-column align-items-center">
          <p class="text-900 text-lg font-semibold">{{ profile.fio }}</p>
          <span class="text-400">{{ profile.username }}</span>
        </div>
      </div>
      <div class="layout-language-button">
        <Button text rounded class="ml-3 p-2" @click="toggleLang">
          <span class="uppercase font-semibold text-xl">{{ profile.language_code }}</span>
        </Button>
      </div>
    </div>
    <div class="flex my-3">
      <div class="p-2 text-center w-full border-1 border-round-left border-300 cursor-pointer" :class="{ 'bg-blue-100': mode == 'light' }" @click="changeMode('light')">
        <i class="block pi pi-sun text-xl" :class="{ 'text-blue-700': mode == 'light' }"></i>
        <label :class="{ 'text-blue-700': mode == 'light' }" for="">{{ $t("light") }}</label>
      </div>
      <div class="p-2 text-center w-full border-1 border-round-right border-300 cursor-pointer" :class="{ 'bg-blue-900': mode == 'dark' }" @click="changeMode('dark')">
        <i class="block pi pi-moon text-xl" :class="{ 'text-blue-500': mode == 'dark' }"></i>
        <label :class="{ 'text-blue-500': mode == 'dark' }" for="">{{ $t("dark") }}</label>
      </div>
    </div>
    <div class="layout-menu">
      <ul>
        <li>
          <a @click="editPass()"> <i class="ong-key mr-2"></i>{{ $t("change_password") }}</a>
        </li>
        <li>
          <a @click="logout()"> <i class="pi pi-sign-out mr-2 rotate-180"></i>{{ $t("logout") }}</a>
        </li>
      </ul>
    </div>
    <!-- {{ profile }} -->
  </OverlayPanel>

  <OverlayPanel ref="change_lang">
    <div class="layout-menu">
      <ul>
        <li v-for="(lang, index) in act_languages" :key="index">
          <a @click="changeLang(lang)">{{ lang.name }}</a>
        </li>
      </ul>
    </div>
  </OverlayPanel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditPasswordDialog from "./EditPasswordDialog.vue";
export default {
  name: "Topbar",
  components: { EditPasswordDialog },
  props: { is_static: { type: Boolean, default: false } },
  emits: ["update:is_static"],
  data() {
    return {
      dialog_edit_password: false,
    };
  },
  computed: { ...mapGetters(["profile", "mode", "act_languages"]) },

  methods: {
    ...mapActions(["clearLocalStorage", "fetchUpdateProfile"]),
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    toggleLang(event) {
      this.$refs.change_lang.toggle(event);
    },
    changeMode(mode) {
      this.mode = mode;
      this.$store.commit("setMode", mode);
      document.getElementById("theme-link").href = `/themes/theme-${mode}.css`;
    },
    changeLang(lang) {
      this.fetchUpdateProfile({ id: this.profile.id, language_code: lang.code }).then(() => {
        this.$store.dispatch("fetchProfile").then(() => {
          location.reload();
        });
      });
    },
    editPass() {
      this.dialog_edit_password = true;
    },
    logout() {
      this.clearLocalStorage();
      this.$router.push("/auth");
    },
  },
};
</script>
