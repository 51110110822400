<template>
  <div class="layout-menu">
    <ul>
      <template v-for="(item, index) in items" :key="index">
        <sub-menu :item="item" />
      </template>
    </ul>
  </div>
</template>

<script>
import SubMenu from "./SubMenu.vue";
export default {
  name: "Menu",
  components: { SubMenu },
  data() {
    return {
      items: [
        {
          label: this.$t("waybill"),
          icon: "ong-doc",
          to: "/waybill",
          visible: this.$assent("waybill-index"),
        },
        {
          label: this.$t("fault_act"),
          icon: "ong-warning-rectangle",
          to: "/fault-act",
          visible: this.$assent("fault_act-index"),
        },
        {
          label: this.$t("receipt_document"),
          icon: "ong-in-link",
          to: "/receipt-document",
          visible: this.$assent("receipt_document-index"),
        },
        {
          label: this.$t("consumpt_document"),
          icon: "ong-out-link",
          to: "/consumpt-document",
          visible: this.$assent("consumpt_document-index"),
        },
        {
          label: this.$t("order"),
          icon: "ong-id-card",
          to: "/order",
          visible: this.$assent("order-index"),
        },
        {
          label: this.$t("transport"),
          icon: "ong-truck",
          to: "/transport",
          visible: this.$assent("transport-index"),
        },
        {
          label: this.$t("driver"),
          icon: "ong-users",
          to: "/driver",
          visible: this.$assent("driver-index"),
        },
        {
          label: this.$t("spare_part_stock"),
          icon: "pi pi-wrench",
          to: "/spare-part-stock",
          visible: this.$assent("spare_part-index"),
        },
        {
          label: this.$t("reports"),
          icon: "pi pi-chart-bar",
          visible: true,
          children: [
            {
              label: this.$t("waybill_invoice"),
              icon: "pi pi-chart-line",
              to: "/waybill-invoice",
              visible: this.$assent("waybill-invoice"),
            }
          ]
        },
        {
          label: this.$t("directory"),
          icon: "ong-books-list",
          visible: true,
          children: [
            {
              label: this.$t("spare_part"),
              icon: "pi pi-wrench",
              to: "/spare-part",
              visible: this.$assent("spare_part-index"),
            },
            {
              label: this.$t("dealer"),
              icon: "ong-building-single",
              to: "/dealer",
              visible: this.$assent("dealer-index"),
            },
            {
              label: this.$t("route"),
              icon: "pi pi-map",
              to: "/route",
              visible: this.$assent("route-index"),
            },
            {
              label: this.$t("region"),
              icon: "pi pi-map-marker",
              to: "/region",
              visible: this.$assent("region-index"),
            },
            {
              label: this.$t("department"),
              icon: "ong-contact",
              to: "/department",
              visible: this.$assent("department-index"),
            },
            {
              label: this.$t("order_type"),
              icon: "ong-list",
              to: "/order-type",
              visible: this.$assent("order_type-index"),
            },
            {
              label: this.$t("transport_type"),
              icon: "pi pi-car",
              to: "/transport-type",
              visible: this.$assent("transport_type-index"),
            },
            {
              label: this.$t("spare_part_category"),
              icon: "ong-newspaper",
              to: "/spare-part-category",
              visible: this.$assent("spare_part_category-index"),
            },
            {
              label: this.$t("unit"),
              icon: "ong-list-circle",
              to: "/unit",
              visible: this.$assent("unit-index"),
            },
            {
              label: this.$t("fault_type"),
              icon: "ong-warning",
              to: "/fault-type",
              visible: this.$assent("fault_type-index"),
            },
            {
              label: this.$t("waybill_type"),
              icon: "ong-stack",
              to: "/waybill-type",
              visible: this.$assent("waybill_type-index"),
            },
            {
              label: this.$t("fuel"),
              icon: "ong-flame",
              to: "/fuel",
              visible: this.$assent("fuel-index"),
            },
            {
              label: this.$t("fuel_station"),
              icon: "ong-location-check",
              to: "/fuel-station",
              visible: this.$assent("fuel_station-index"),
            },
          ],
        },
        {
          label: this.$t("admin"),
          icon: "pi pi-cog",
          visible: this.$assent(),
          children: [
            {
              label: this.$t("user"),
              icon: "ong-hr-user",
              to: "/user",
              visible: this.$assent("user-index"),
            },
            {
              label: this.$t("role"),
              icon: "ong-key",
              to: "/role",
              visible: this.$assent("role-index"),
            },
          ],
        },
      ],
    };
  },
};
</script>
