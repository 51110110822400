import axios from "axios";
import store from "..";

export default {
  state: {
    transports: [],
    dd_transports: [],
  },
  getters: {
    transports: (state) => state.transports,
    dd_transports: (state) => state.dd_transports,
  },
  mutations: {
    setTransports(state, transports) {
      state.transports = transports;
    },
    setDdTransports(state, transports) {
      state.dd_transports = transports ?? [];
      // transports ? localStorage.setItem("dd_transports", JSON.stringify(transports)) : localStorage.removeItem("dd_transports");
    },
  },
  actions: {
    fetchTransports(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/transport?${data}`)
          .then((response) => {
            content.commit("setTransports", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateTransport(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/transport`, data)
          .then((response) => {
            resolve(response.data);
            content.state.dd_transports.push(response.data.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateTransport(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/transport/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.state.dd_transports.forEach((element, index) => {
              if (element.id === data.id) content.state.dd_transports[index] = response.data.data;
            });
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowTransport(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/transport/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteTransport(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/transport/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdTransports", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
