import "./assets/css/app.css";
import "./assets/css/primevue.css";
import "./assets/icons/style.css";
import "./assets/css/primeflex.css";
import "primeicons/primeicons.css";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// PrimeVue components
import PrimeVue from "primevue/config";
import Row from "primevue/row";
import Chip from 'primevue/chip';
import Toast from "primevue/toast";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Tooltip from 'primevue/tooltip';
import Calendar from 'primevue/calendar';
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import Password from "primevue/password";
import Skeleton from "primevue/skeleton";
import Textarea from 'primevue/textarea';
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Paginator from "primevue/paginator";
import SpeedDial from 'primevue/speeddial';
import ColumnGroup from "primevue/columngroup";
import InputNumber from 'primevue/inputnumber';
import InputSwitch from "primevue/inputswitch";
import MultiSelect from 'primevue/multiselect';
import ProgressBar from "primevue/progressbar";
import AutoComplete from 'primevue/autocomplete';
import ConfirmPopup from "primevue/confirmpopup";
import OverlayPanel from "primevue/overlaypanel";
import ToastService from "primevue/toastservice";
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from "primevue/confirmationservice";

// Local global components
import OngAvatar from "./components/OngAvatar.vue";
import OngStatus from "./components/OngStatus.vue";

// Local global services
import globalMixin from "@/service";

const app = createApp(App);


app.use(store);
app.use(router);
app.use(ToastService);
app.use(ConfirmationService);
app.use(PrimeVue, {
  locale: {
    startsWith: "Starts with",
    contains: "Contains",
    notContains: "Not contains",
    endsWith: "Ends with",
    equals: "Equals",
    notEquals: "Not equals",
    noFilter: "No Filter",
    lt: "Less than",
    lte: "Less than or equal to",
    gt: "Greater than",
    gte: "Greater than or equal to",
    dateIs: "Date is",
    dateIsNot: "Date is not",
    dateBefore: "Date is before",
    dateAfter: "Date is after",
    clear: t("clear"),
    apply: "Apply",
    matchAll: "Match All",
    matchAny: "Match Any",
    addRule: "Add Rule",
    removeRule: "Remove Rule",
    accept: t("yes"),
    reject: t("no"),
    choose: "Choose",
    upload: "Upload",
    cancel: "Cancel",
    dayNames: [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
    dayNamesShort: [t("sun"), t("mon"), t("tue"), t("wed"), t("thu"), t("fri"), t("sat")],
    dayNamesMin: [t("su"), t("mo"), t("tu"), t("we"), t("th"), t("fr"), t("sa")],
    monthNames: [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
    monthNamesShort: [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"), t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")],
    today: t("today"),
    weekHeader: "Wk",
    firstDayOfWeek: 0,
    dateFormat: t("dd/mm/yy"),
    weak: "Weak",
    medium: "Medium",
    strong: "Strong",
    passwordPrompt: "Enter a password",
    emptyFilterMessage: t("no_results_found"),
    emptyMessage: t("no_data"),
  },
}, { ripple: true });

// PrimeVue components
app.component("Row", Row);
app.component("Chip", Chip);
app.component("Toast", Toast);
app.component("Column", Column);
app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("Calendar", Calendar);
app.component("Checkbox", Checkbox);
app.component("Dropdown", Dropdown);
app.component("Password", Password);
app.component("Skeleton", Skeleton);
app.component("Textarea", Textarea);
app.component("InputText", InputText);
app.component("DataTable", DataTable);
app.component("Paginator", Paginator);
app.component("SpeedDial", SpeedDial);
app.component("ColumnGroup", ColumnGroup);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("MultiSelect", MultiSelect);
app.component("ProgressBar", ProgressBar);
app.component("AutoComplete", AutoComplete);
app.component("ConfirmPopup", ConfirmPopup);
app.component("OverlayPanel", OverlayPanel);
app.component("ConfirmDialog", ConfirmDialog);


// PrimeVue directive
app.directive('tooltip', Tooltip);

// Local global components
app.component("OngAvatar", OngAvatar);
app.component("OngStatus", OngStatus);

// Local global services
app.mixin(globalMixin);

//--------------------------------------------------------------------------------------
function t(text) {
  let content_words = JSON.parse(localStorage.getItem("phrases")) ?? [];
  content_words.find((v) => {
    if (v.word.trim() === text) {
      text = v.translation;
    }
  });
  return text;
}
//--------------------------------------------------------------------------------------
app.config.globalProperties.$t = t;
//--------------------------------------------------------------------------------------
app.config.globalProperties.$assent = (text) => {
  let permissions = store.getters.user_permissions;
  let roles = store.getters.user_roles;
  if (roles.includes("superadmin") || roles.includes("admin")) {
    return true;
  } else if (permissions.includes(text)) {
    return true;
  } else {
    return false;
  }
};
//--------------------------------------------------------------------------------------
axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
};
//-- input error message return ---------------------------------------------
app.config.globalProperties.$errMes = (error) => {
  if (error.$errors[0])
    if (error.$errors[0].$params.type == "required") return t("value_required");
    else if (error.$errors[0].$params.type == "maxValue") return `${t("max_value")}: ${parseFloat(error.$errors[0].$params.max).toLocaleString("ru-RU")}`;
    else if (error.$errors[0].$params.type == "minValue") return `${t("min_value")}: ${parseFloat(error.$errors[0].$params.min).toLocaleString("ru-RU")}`;
    else if (error.$errors[0].$params.type == "maxLength") return `${t("max_length")}: ${error.$errors[0].$params.max}`;
    else if (error.$errors[0].$params.type == "minLength") return `${t("min_length")}: ${error.$errors[0].$params.min}`;
    else if (error.$errors[0].$params.type == "email") return t("email_not_valid");
    else if (error.$errors[0].$params.type == "sameAs") return t("invalid_password");
  return "";
};
setInterval(() => {
  // console.clear();
}, 10000);
app.mount("#app");
