import axios from "axios";
import store from "..";

export default {
  state: {
    routes: [],
    dd_routes: [],
  },
  getters: {
    routes: (state) => state.routes,
    dd_routes: (state) => state.dd_routes,
  },
  mutations: {
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setDdRoutes(state, routes) {
      state.dd_routes = routes ?? [];
      // routes ? localStorage.setItem("dd_routes", JSON.stringify(routes)) : localStorage.removeItem("dd_routes");
    },
  },
  actions: {
    fetchRoutes(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/route?${data}`)
          .then((response) => {
            content.commit("setRoutes", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateRoute(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/route`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdRoutes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateRoute(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/route/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdRoutes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowRoute(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/route/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteRoute(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/route/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdRoutes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
