import axios from "axios";
import store from "..";

export default {
  state: {
    drivers: [],
    dd_drivers: [],
  },
  getters: {
    drivers: (state) => state.drivers,
    dd_drivers: (state) => state.dd_drivers,
  },
  mutations: {
    setDrivers(state, drivers) {
      state.drivers = drivers;
    },
    setDdDrivers(state, drivers) {
      state.dd_drivers = drivers ?? [];
      // drivers ? localStorage.setItem("dd_drivers", JSON.stringify(drivers)) : localStorage.removeItem("dd_drivers");
    },
  },
  actions: {
    fetchDrivers(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/driver?${data}`)
          .then((response) => {
            content.commit("setDrivers", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateDriver(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/driver`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdDrivers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateDriver(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/driver/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDrivers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowDriver(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/driver/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteDriver(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/driver/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDrivers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
