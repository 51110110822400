import axios from "axios";
import store from "..";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWaybillInvoices(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/waybill-invoice?${payload}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
