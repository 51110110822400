import axios from "axios";
import store from "..";

export default {
  state: {
    fault_types: [],
    dd_fault_types: [],
  },
  getters: {
    fault_types: (state) => state.fault_types,
    dd_fault_types: (state) => state.dd_fault_types,
  },
  mutations: {
    setFaultTypes(state, fault_types) {
      state.fault_types = fault_types;
    },
    setDdFaultTypes(state, fault_types) {
      state.dd_fault_types = fault_types ?? [];
      // fault_types ? localStorage.setItem("dd_fault_types", JSON.stringify(fault_types)) : localStorage.removeItem("dd_fault_types");
    },
  },
  actions: {
    fetchFaultTypes(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fault-type?${data}`)
          .then((response) => {
            content.commit("setFaultTypes", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateFaultType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/fault-type`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdFaultTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateFaultType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/fault-type/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFaultTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowFaultType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fault-type/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteFaultType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/fault-type/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFaultTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
