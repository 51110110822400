<template>
  <i class="mr-2" :class="item.icon" style="font-size:19px"></i>
  <span>{{ $t(item.label) }}</span>
  <i v-if="item.children" class="pi pi-fw pi-angle-down layout-submenu-toggler" :class="{ 'pi-angle-up': !show_submenu }"></i>
</template>

<script>
export default {
  name: "ContextMenu",
  props: { item: { type: Object, required: true }, show_submenu: { type: Boolean, default: true } },
};
</script>
