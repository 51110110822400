import axios from "axios";
import store from "..";

export default {
  state: {
    spare_parts: [],
    dd_spare_parts: [],
  },
  getters: {
    spare_parts: (state) => state.spare_parts,
    dd_spare_parts: (state) => state.dd_spare_parts,
  },
  mutations: {
    setSpareParts(state, spare_parts) {
      state.spare_parts = spare_parts;
    },
    setDdSpareParts(state, spare_parts) {
      state.dd_spare_parts = spare_parts ?? [];
      // spare_parts ? localStorage.setItem("dd_spare_parts", JSON.stringify(spare_parts)) : localStorage.removeItem("dd_spare_parts");
    },
  },
  actions: {
    fetchSpareParts(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/spare-part?${data}`)
          .then((response) => {
            content.commit("setSpareParts", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateSparePart(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/spare-part`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdSpareParts", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateSparePart(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/spare-part/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdSpareParts", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowSparePart(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/spare-part/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteSparePart(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/spare-part/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdSpareParts", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchSparePartDetails(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/spare-part-detail/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
