import axios from "axios";
import store from "..";

export default {
  state: {
    receipt_documents: [],
    dd_receipt_documents: [],
  },
  getters: {
    receipt_documents: (state) => state.receipt_documents,
    dd_receipt_documents: (state) => state.dd_receipt_documents,
  },
  mutations: {
    setReceiptDocuments(state, receipt_documents) {
      state.receipt_documents = receipt_documents;
    },
    setDdReceiptDocuments(state, receipt_documents) {
      state.dd_receipt_documents = receipt_documents ?? [];
      // receipt_documents ? localStorage.setItem("dd_receipt_documents", JSON.stringify(receipt_documents)) : localStorage.removeItem("dd_receipt_documents");
    },
  },
  actions: {
    fetchReceiptDocuments(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/receipt-document?${data}`)
          .then((response) => {
            content.commit("setReceiptDocuments", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateReceiptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/receipt-document`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdReceiptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateReceiptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/receipt-document/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdReceiptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowReceiptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/receipt-document/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteReceiptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/receipt-document/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdReceiptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
