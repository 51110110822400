<template>
  <div class="layout-main-container">
    <div class="layout-main overflow-hidden">
      <div class="h-full m-0 grid">
        <div class="col-12 p-0 h-full overflow-hidden">
          <template v-if="$route.path == '/'">
            <div class="flex flex-column h-full overflow-hidden">
              <router-view />
            </div>
          </template>
          <template v-else>
            <div class="card flex flex-column h-full overflow-hidden">
              <router-view />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Container",
  computed: { ...mapGetters(["user_roles"]) },
};
</script>
