import axios from "axios";

export default {
  state: {},
  getters: {},
  mutations: {
    setAccessToken(state, token) {
      localStorage.setItem("access_token", token);
      axios.defaults.headers.common = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    },
  },
  actions: {
    fetchToken(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.rootState.backend_url}api/auth/login`, {
            username: data.username,
            password: data.password,
            grant_type: "password",
            client_id: 2,
            client_secret: "Mt57LfRyUwwWIuKfSXnNzQAeWxQY0JFNerkrLymd",
            is_remember: data.is_remember,
          })
          .then((response) => {
            context.commit("setAccessToken", response.data.access_token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
