import axios from "axios";
import store from "..";

export default {
  state: {
    phrases: JSON.parse(localStorage.getItem("phrases")) ?? [],
  },
  getters: {
    phrases: (state) => state.phrases,
  },
  mutations: {
    setPhrases(state, phrases) {
      state.phrases = phrases;
      localStorage.setItem("phrases", JSON.stringify(phrases ?? []));
    },
  },
  actions: {
    getSystemPhrases(context, data) {
      return new Promise((resolve, reject) => {
        this.dispatch("fetchPublicPhrases", data)
          .then((response) => {
            context.commit("setPhrases", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchPublicPhrases(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/public/get-phrase?${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchPhrases(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/phrase?${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
