import axios from "axios";
import store from ".";

export default {
  state: {
    profile: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : {},
    act_languages: JSON.parse(localStorage.getItem("act_languages")) || [],
    user_roles: [],
    user_permissions: [],
  },
  getters: {
    profile: (state) => state.profile,
    act_languages: (state) => state.act_languages,
    user_roles: (state) => state.user_roles,
    user_permissions: (state) => state.user_permissions,
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
      this.commit("setUserRoles", profile.roles);
      this.commit("setUserPermissions", profile.permissions);
      localStorage.setItem("profile", JSON.stringify(profile ?? {}));
    },
    setUserRoles(state, roles) {
      state.user_roles = roles;
    },
    setUserPermissions(state, permissions) {
      state.user_permissions = permissions;
    },
    setActiveLanguages(state, data) {
      state.act_languages = data;
      localStorage.setItem("act_languages", JSON.stringify(data ?? []));
    },
  },
  actions: {
    fetchProfile(context) {
      if (!localStorage.getItem("act_languages")) this.dispatch("fetchActiveLanguage");
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/user/profile`)
          .then((response) => {
            context.commit("setProfile", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchActiveLanguage(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/public/language`)
          .then((response) => {
            context.commit("setActiveLanguages", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
