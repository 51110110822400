<template>
  <div class="avatar" :style="`width: ${size}px; height: ${size}px`" @click="detailInfo">
    <template v-if="remove">
      <div class="close" @click="removeItem" :style="`width: ${size}px; height: ${size}px; border-radius: 50%;`">
        <i class="font-700 ong-close"></i>
      </div>
    </template>
    <template v-if="show_image && item.avatar">
      <div class="show_image" @click="showImage" :style="`width: ${size}px; height: ${size}px; border-radius: 50%;`">
        <i class="font-700 ong-eye-show"></i>
      </div>
    </template>

    <template v-if="item.avatar">
      <template v-if="!user_avatars.find((p) => p.id == item.user_id)?.photo">
        <Skeleton shape="circle" :size="`${size}px`" class=""></Skeleton>
      </template>
      <div v-else class="relative" :class="border" :style="`background-image: url(${user_avatars.find((p) => p.id == item.user_id)?.photo}); background-size: ${size}px; background-repeat: no-repeat; background-position: center; width: ${size}px; height: ${size}px; border-radius: 50%;`">
        <div v-if="!hide_status" class="avatar-status" :style="`min-width: ${size / 3}px; min-height: ${size / 3}px; border-radius: ${size / 5}px; font-size: ${size / 5.5}px; padding: ${size / 10}px;`">
          <span v-if="size >= 40 && !hide_text"></span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no-avatar" :style="`width: ${size}px; height: ${size}px; border-radius: 50%; font-size: ${size / 2}px`">
        <span class="line-height-1 flex">{{ getName() }}</span>
        <div v-if="!hide_status" class="avatar-status" :style="`min-width: ${size / 3}px; min-height: ${size / 3}px; border-radius: ${size / 5}px; font-size: ${size / 5.5}px; padding: ${size / 10}px;`">
          <span v-if="size >= 40 && !hide_text"></span>
        </div>
      </div>
    </template>

    <!-- <OverlayPanel ref="info_employee" :style="{ width: '320px' }" @hide="$emit('hide')">
      <detailed-info-employee :item="item" detail_remove @remove="$emit('remove')"> </detailed-info-employee>
    </OverlayPanel> -->
    <!-- <show-image v-if="dialog_show_image" :item="item" @hide="dialog_show_image = false" /> -->
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
// import DetailedInfoEmployee from "@/components/DetailedInfoEmployee.vue";
// import ShowImage from "./ShowImage.vue";

export default {
  // components: { ShowImage, DetailedInfoEmployee },
  name: "OngAvatar",
  emits: ["remove", "hide"],
  props: {
    item: { type: Object, default: () => ({}) },
    size: { type: Number, default: 32 },
    border: { type: String, default: "" },
    remove: { type: Boolean, default: false },
    detail_remove: { type: Boolean, default: false },
    is_detail: { type: Boolean, default: false },
    removeTrue: { type: Boolean, default: false },
    hide_status: { type: Boolean, default: true },
    hide_text: { type: Boolean, default: false },
    show_image: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog_employee: false,
      dialog_show_image: false,
      event: null,
      avatarPhoto: null,
      loading: true,
    };
  },
  computed: { ...mapGetters(["backend_url", "employee", "user_avatars"]) },
  watch: {
    user_avatars: {
      handler() {
        this.getPhoto(0, this.user_avatars[0]);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["fetchShowEmployee"]),
    detailInfo(e) {
      if (this.is_detail) {
        e.stopPropagation();
        this.$refs.info_employee.toggle(e);
      }
    },
    removeItem(e) {
      e.stopPropagation();
      this.$emit("remove");
    },
    showImage() {
      this.dialog_show_image = true;
    },
    getUserAvatars(item) {
      if (!item.avatar) return;
      else if (this.user_avatars.find((p) => p.id == item.user_id)) return;
      else {
        let avatars = this.user_avatars.length ? this.user_avatars : [];
        avatars.push({
          id: item.user_id,
          avatar: item.avatar,
          loading: true,
        });
        this.$store.commit("setUserAvatars", avatars);
      }
    },
    getPhoto(i, item) {
      if (item.loading) {
        item.loading = false;
        axios({
          url: `${this.backend_url}api/master/user/avatar/${item.id}`,
          method: "GET",
          responseType: "blob",
        }).then((res) => {
          let reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            item.photo = reader.result;
          };
        });
      } else if (this.user_avatars[i + 1]) this.getPhoto(++i, this.user_avatars[i]);
    },
    getName() {
      let name = this.item.fio ? this.item.fio.split(" ") : [];
      let first = name[0] ? name[0].charAt(0) : "";
      let last = name[1] ? name[1].charAt(0) : "";
      return first + last;
    },
  },
  mounted() {
    this.getUserAvatars(this.item);
  },
};
</script>

<style scoped>
.avatar:hover .close {
  display: flex;
}
.avatar:hover .show_image {
  display: flex;
}
.no-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, var(--blue-400) 0%, var(--blue-700) 100%);
  color: var(--surface-a);
}
.close,
.show_image {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  color: var(--surface-a);
  z-index: 1;
  /* cursor: pointer; */
}
.close {
  background-color: var(--red-500);
}
.show_image {
  background-color: rgba(0, 0, 0, 0.6);
}
.avatar-status {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  background: green;
  line-height: 0.5;
  border: 1px solid var(--surface-a);
  color: var(--surface-a);
}
</style>
