import axios from "axios";
import store from "..";

export default {
  state: {
    units: [],
    dd_units: [],
  },
  getters: {
    units: (state) => state.units,
    dd_units: (state) => state.dd_units,
  },
  mutations: {
    setUnits(state, units) {
      state.units = units;
    },
    setDdUnits(state, units) {
      state.dd_units = units ?? [];
      // units ? localStorage.setItem("dd_units", JSON.stringify(units)) : localStorage.removeItem("dd_units");
    },
  },
  actions: {
    fetchUnits(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/unit?${data}`)
          .then((response) => {
            content.commit("setUnits", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateUnit(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/unit`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdUnits", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateUnit(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/unit/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdUnits", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowUnit(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/unit/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteUnit(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/unit/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdUnits", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
