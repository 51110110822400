export default {
  methods: {
    ongDateFormat(date, format = null) {
      if (date) {
        if (format == "datetime") return new Date(date).toLocaleString("ru-RU", { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" });
        else return new Date(date).toLocaleString("ru-RU", { year: "numeric", month: "numeric", day: "numeric" });
      } else return date;
    },
  },
};
