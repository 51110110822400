import axios from "axios";
import store from "..";

export default {
  state: {
    dealers: [],
    dd_dealers: [],
  },
  getters: {
    dealers: (state) => state.dealers,
    dd_dealers: (state) => state.dd_dealers,
  },
  mutations: {
    setDealers(state, dealers) {
      state.dealers = dealers;
    },
    setDdDealers(state, dealers) {
      state.dd_dealers = dealers ?? [];
      // dealers ? localStorage.setItem("dd_dealers", JSON.stringify(dealers)) : localStorage.removeItem("dd_dealers");
    },
  },
  actions: {
    fetchDealers(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/dealer?${data}`)
          .then((response) => {
            content.commit("setDealers", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateDealer(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/dealer`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDealers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateDealer(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/dealer/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDealers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowDealer(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/dealer/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteDealer(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/dealer/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDealers", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
