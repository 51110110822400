export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    clearLocalStorage() {
      let mode = localStorage.getItem("mode") || "light";
      let phrases = localStorage.getItem("phrases");
      localStorage.clear();
      localStorage.setItem("mode", mode);
      localStorage.setItem("phrases", phrases);
    },
  },
};
