<template>
  <Dialog v-model:visible="visible" modal :header="$t('change_password')" :style="{ width: '510px' }" @hide="$emit('close')">
    <div>
      <div class="mb-3">
        <label for="">{{ $t("old_password") }} <span class="text-red-600"> *</span></label>
        <div class="p-fluid mt-1">
          <Password v-model="old_password" toggleMask :feedback="false" class="w-full" :class="v$.old_password.$error ? 'p-invalid' : ''" :placeholder="$t('enter_old_password')" />
        </div>
        <small class="p-error" id="">{{ $errMes(v$.old_password) }}</small>
      </div>
      <div class="mb-3">
        <label for="">{{ $t("new_password") }} <span class="text-red-600"> *</span></label>
        <div class="p-fluid mt-1">
          <Password v-model="password" toggleMask :feedback="false" class="w-full" :class="v$.password.$error ? 'p-invalid' : ''" :placeholder="$t('enter_new_password')" />
        </div>
        <small class="p-error" id="">{{ $errMes(v$.password) }}</small>
      </div>
      <div class="mb-3">
        <label for="">{{ $t("password_confirm") }} <span class="text-red-600"> *</span></label>
        <div class="p-fluid mt-1">
          <Password v-model="password_confirm" toggleMask :feedback="false" class="w-full" :class="v$.password_confirm.$error ? 'p-invalid' : ''" :placeholder="$t('enter_password_confirm')" />
        </div>
        <small class="p-error" id="">{{ $errMes(v$.password_confirm) }}</small>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('cancel')" icon="pi pi-times" @click="$emit('close')" text />
      <Button :label="$t('save')" :loading="loading" icon="pi pi-check" @click="save()" text />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { mapActions } from "vuex";

export default {
  name: "EditPasswordDialog",
  data() {
    return {
      visible: true,
      loading: false,
      old_password: "",
      password: "",
      password_confirm: "",
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      old_password: { required },
      password: { required, minLength: minLength(8) },
      password_confirm: { required },
    };
  },
  methods: {
    ...mapActions(["fetchResetPassword"]),
    save() {
      this.v$.$validate().then((valid) => {
        if (valid) {
          this.loading = true;
          let data = { old_password: this.old_password, password: this.password, password_confirm: this.password_confirm };
          this.fetchResetPassword(data)
            .then(() => {
              this.$emit("close");
              this.$toast.add({ severity: "success", summary: this.$t("successfully_change_password"), life: 3000 });
              this.loading = false;
            })
            .catch(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
