import fuel from "./fuel";
import user from "./user";
import role from "./role";
import unit from "./unit";
import order from "./order";
import route from "./route";
import dealer from "./dealer";
import driver from "./driver";
import phrase from "./phrase";
import report from "./report";
import regions from "./regions";
import waybill from "./waybill";
import fault_act from "./fault_act";
import transport from "./transport";
import department from "./department";
import fault_type from "./fault_type";
import order_type from "./order_type";
import spare_part from "./spare_part";
import fuel_station from "./fuel_station";
import waybill_type from "./waybill_type";
import transport_type from "./transport_type";
import receipt_document from "./receipt_document";
import consumpt_document from "./consumpt_document";
import spare_part_category from "./spare_part_category";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    fuel,
    user,
    role,
    unit,
    order,
    route,
    dealer,
    driver,
    phrase,
    report,
    regions,
    waybill,
    fault_act,
    transport,
    department,
    fault_type,
    order_type,
    spare_part,
    fuel_station,
    waybill_type,
    transport_type,
    receipt_document,
    consumpt_document,
    spare_part_category,
  },
};
