<template>
  <li v-if="item.visible">
    <router-link v-if="item.to" :to="item.to" :class="{'active-route': item.to === $route.path}"> <context-menu :item="item" /> </router-link>
    <a v-else-if="item.url" :href="item.url" :class="{'active-route': item.to === $route.path}"> <context-menu :item="item" /> </a>
    <a v-else @click="toggle()"> <context-menu :item="item" :show_submenu="show_submenu"/> </a>
    <template v-if="item.children && show_submenu">
      <template v-for="(child, index) in item.children" :key="index">
        <sub-menu class="pl-3" :item="child" />
      </template>
    </template>
  </li>
</template>

<script>
import SubMenu from "./SubMenu.vue";
import ContextMenu from "./Context.vue";

export default {
  name: "SubMenu",
  components: { ContextMenu, SubMenu },
  props: { item: { type: Object, required: true } },
  data() {
    return {
      active: false,
      show_submenu: true,
    };
  },
  methods: {
    toggle() {
      this.show_submenu = !this.show_submenu;
    },
  },
};
</script>
