import axios from "axios";
import store from "..";

export default {
  state: {
    fuels: [],
    dd_fuels: [],
  },
  getters: {
    fuels: (state) => state.fuels,
    dd_fuels: (state) => state.dd_fuels,
  },
  mutations: {
    setFuels(state, fuels) {
      state.fuels = fuels;
    },
    setDdFuels(state, fuels) {
      state.dd_fuels = fuels ?? [];
      // fuels ? localStorage.setItem("dd_fuels", JSON.stringify(fuels)) : localStorage.removeItem("dd_fuels");
    },
  },
  actions: {
    fetchFuels(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fuel?${data}`)
          .then((response) => {
            content.commit("setFuels", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateFuel(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/fuel`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdFuels", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateFuel(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/fuel/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFuels", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowFuel(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fuel/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteFuel(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/fuel/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFuels", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
