<template>
  <span class="border-round px-2 py-1 text uppercase font-semibold" :class="statusColor()">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "OngStatus",
  props: {
    value: { type: String, default: () => "" },
    code: { type: Number, default: () => 10 },
  },
  methods: {
    statusColor() {
      let result = "";
      switch (this.code) {
        case 10:
          result = "text-blue-800 bg-blue-200";
          break;
        case 20:
          result = "text-green-800 bg-green-200";
          break;
        case 30:
          result = "text-yellow-800 bg-yellow-200";
          break;
        case 40:
          result = "text-red-800 bg-red-200";
          break;
        case 50:
          result = "text-gray-800 bg-gray-200";
          break;
        case 60:
          result = "text-cyan-800 bg-cyan-200";
          break;
        default:
          result = "text-bluegray-800 bg-bluegray-200";
          break;
      }
      return result;
    },
  },
};
</script>
