import axios from "axios";
import store from "..";

export default {
  state: {
    order_types: [],
    dd_order_types: [],
  },
  getters: {
    order_types: (state) => state.order_types,
    dd_order_types: (state) => state.dd_order_types,
  },
  mutations: {
    setOrderTypes(state, order_types) {
      state.order_types = order_types;
    },
    setDdOrderTypes(state, order_types) {
      state.dd_order_types = order_types ?? [];
      // localStorage.setItem("dd_order_types", JSON.stringify(order_types));
    },
  },
  actions: {
    fetchOrderTypes(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/order-type?${data}`)
          .then((response) => {
            content.commit("setOrderTypes", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateOrderType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/order-type`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdOrderTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateOrderType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/order-type/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdOrderTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowOrderType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/order-type/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteOrderType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/order-type/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdOrderTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
