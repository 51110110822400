import axios from "axios";
import store from "..";

export default {
  state: {
    orders: [],
    dd_orders: [],
  },
  getters: {
    orders: (state) => state.orders,
    dd_orders: (state) => state.dd_orders,
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },
    setDdOrders(state, orders) {
      state.dd_orders = orders ?? [];
      // orders ? localStorage.setItem("dd_orders", JSON.stringify(orders)) : localStorage.removeItem("dd_orders");
    },
  },
  actions: {
    fetchOrders(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/order?${data}`)
          .then((response) => {
            content.commit("setOrders", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateOrder(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/order`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdOrders", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateOrder(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/order/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdOrders", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowOrder(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/order/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteOrder(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/order/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdOrders", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchEnumOrderStatus(content, data = "") {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/enum/list-order-statuses?${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchOrderReport(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/order-report?${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
