import axios from "axios";
import store from "..";

export default {
  state: {
    fuel_stations: [],
    dd_fuel_stations: [],
  },
  getters: {
    fuel_stations: (state) => state.fuel_stations,
    dd_fuel_stations: (state) => state.dd_fuel_stations,
  },
  mutations: {
    setFuelStations(state, fuel_stations) {
      state.fuel_stations = fuel_stations;
    },
    setDdFuelStations(state, fuel_stations) {
      state.dd_fuel_stations = fuel_stations ?? [];
      // fuel_stations ? localStorage.setItem("dd_fuel_stations", JSON.stringify(fuel_stations)) : localStorage.removeItem("dd_fuel_stations");
    },
  },
  actions: {
    fetchFuelStations(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fuel-station?${data}`)
          .then((response) => {
            content.commit("setFuelStations", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateFuelStation(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/fuel-station`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdFuelStations", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateFuelStation(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/fuel-station/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFuelStations", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowFuelStation(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fuel-station/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteFuelStation(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/fuel-station/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFuelStations", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
