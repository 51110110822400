import axios from "axios";
import store from ".";

export default {
  state: {
    dd_fuel_types: [],
  },
  getters: {
    dd_fuel_types: (state) => state.dd_fuel_types,
  },
  mutations: {
    setDdFuelTypes(state, fuel_types) {
      state.dd_fuel_types = fuel_types ?? [];
      fuel_types ? localStorage.setItem("dd_fuel_types", JSON.stringify(fuel_types)) : localStorage.removeItem("dd_fuel_types");
    },
  },
  actions: {
    fetchFuelTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/enum/list-fuel-types`)
          .then((response) => {
            let data = response.data.map((element) => {
              element.name = t(element.name);
              return element;
            });
            store.commit("setDdFuelTypes", data);
            resolve(data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};

function t(text) {
  let content_words = JSON.parse(localStorage.getItem("phrases")) ?? [];
  content_words.find((v) => {
    if (v.word.trim() === text) {
      text = v.translation;
    }
  });
  return text;
}
