import axios from "axios";
import store from "..";

export default {
  state: {
    waybill_types: [],
    dd_waybill_types: [],
  },
  getters: {
    waybill_types: (state) => state.waybill_types,
    dd_waybill_types: (state) => state.dd_waybill_types,
  },
  mutations: {
    setWaybillTypes(state, waybill_types) {
      state.waybill_types = waybill_types;
    },
    setDdWaybillTypes(state, waybill_types) {
      state.dd_waybill_types = waybill_types ?? [];
      // waybill_types ? localStorage.setItem("dd_waybill_types", JSON.stringify(waybill_types)) : localStorage.removeItem("dd_waybill_types");
    },
  },
  actions: {
    fetchWaybillTypes(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/waybill-type?${data}`)
          .then((response) => {
            content.commit("setWaybillTypes", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateWaybillType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/waybill-type`, data)
          .then((response) => {
            resolve(response.data.data);
            content.state.dd_waybill_types.push(response.data.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateWaybillType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/waybill-type/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.state.dd_waybill_types.forEach((element, index) => {
              if (element.id === data.id) {
                content.state.dd_waybill_types[index] = response.data.data;
              }
            });
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowWaybillType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/waybill-type/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteWaybillType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/waybill-type/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdWaybillTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
