import axios from "axios";
import store from "..";

export default {
  state: {
    departments: [],
    dd_departments: [],
  },
  getters: {
    departments: (state) => state.departments,
    dd_departments: (state) => state.dd_departments,
  },
  mutations: {
    setDepartments(state, departments) {
      state.departments = departments;
    },
    setDdDepartments(state, departments) {
      state.dd_departments = departments ?? [];
      // departments ? localStorage.setItem("dd_departments", JSON.stringify(departments)) : localStorage.removeItem("dd_departments");
    },
  },
  actions: {
    fetchDepartments(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/department?${data}`)
          .then((response) => {
            content.commit("setDepartments", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateDepartment(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/department`, data)
          .then((response) => {
            resolve(response.data);
            content.state.dd_departments.push(response.data.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateDepartment(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/department/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.state.dd_departments.forEach((element, index) => {
              if (element.id === data.id) content.state.dd_departments[index] = response.data.data;
            });
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowDepartment(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/department/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteDepartment(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/department/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdDepartments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
