import { createStore } from "vuex";
import login from "./login";
import pages from "./pages";
import layout from "./layout";
import common from "./common";
import enum_list from "./enum_list";

export default createStore({
  state: {
    backend_url: process.env.VUE_APP_BACKEND_URL,
    mode: localStorage.getItem("mode") ? localStorage.getItem("mode") : "light",
    server_error: null,
  },
  getters: {
    backend_url: (state) => state.backend_url,
    mode: (state) => state.mode,
    server_error: (state) => state.server_error,
  },
  mutations: {
    setServerError(state, error) {
      state.server_error = error;
    },
    setMode(state, mode) {
      state.mode = mode;
      localStorage.setItem("mode", mode);
    },
  },
  actions: {},
  modules: {
    login,
    pages,
    layout,
    common,
    enum_list,
  },
});
