<template>
  <router-view v-if="!loading"/>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return { loading: true };
  },
  computed: { ...mapGetters(["mode", "profile"]) },
  methods: {},
  mounted() {
    if (!localStorage.getItem("mode")) {
      document.getElementById("theme-link").href = `/themes/theme-light.css`;
      localStorage.setItem("mode", "light");
    } else document.getElementById("theme-link").href = `/themes/theme-${this.mode != "dark" ? "light" : this.mode}.css`;
    this.$store.dispatch("getSystemPhrases", `language_code=${this.profile.language_code ?? 'ru'}`).then(() => {
      this.loading = false;
    });
  },
};
</script>
