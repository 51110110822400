import axios from "axios";
import store from "..";

export default {
  state: {
    regions: [],
    dd_regions: [],
  },
  getters: {
    regions: (state) => state.regions,
    dd_regions: (state) => state.dd_regions,
  },
  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
    setDdRegions(state, regions) {
      state.dd_regions = regions ?? [];
      // regions ? localStorage.setItem("dd_regions", JSON.stringify(regions)) : localStorage.removeItem("dd_regions");
    },
  },
  actions: {
    fetchRegions(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/region?${data}`)
          .then((response) => {
            content.commit("setRegions", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateRegion(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/region`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdRegions", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateRegion(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/region/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdRegions", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowRegion(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/region/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteRegion(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/region/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdRegions", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
