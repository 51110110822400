import axios from "axios";
import store from "..";

export default {
  state: {
    transport_types: [],
    dd_transport_types: [],
  },
  getters: {
    transport_types: (state) => state.transport_types,
    dd_transport_types: (state) => state.dd_transport_types,
  },
  mutations: {
    setTransportTypes(state, transport_types) {
      state.transport_types = transport_types;
    },
    setDdTransportTypes(state, transport_types) {
      state.dd_transport_types = transport_types ?? [];
      // transport_types ? localStorage.setItem("dd_transport_types", JSON.stringify(transport_types)) : localStorage.removeItem("dd_transport_types");
    },
  },
  actions: {
    fetchTransportTypes(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/transport-type?${data}`)
          .then((response) => {
            content.commit("setTransportTypes", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateTransportType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/transport-type`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdTransportTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateTransportType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/transport-type/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdTransportTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowTransportType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/transport-type/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteTransportType(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/transport-type/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdTransportTypes", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
