import axios from "axios";
import store from "..";

export default {
  state: {
    spare_part_categories: [],
    dd_spare_part_categories: [],
  },
  getters: {
    spare_part_categories: (state) => state.spare_part_categories,
    dd_spare_part_categories: (state) => state.dd_spare_part_categories,
  },
  mutations: {
    setSparePartCategories(state, spare_part_categories) {
      state.spare_part_categories = spare_part_categories;
    },
    setDdSparePartCategories(state, spare_part_categories) {
      state.dd_spare_part_categories = spare_part_categories ?? [];
      // spare_part_categories ? localStorage.setItem("dd_spare_part_categories", JSON.stringify(spare_part_categories)) : localStorage.removeItem("dd_spare_part_categories");
    },
  },
  actions: {
    fetchSparePartCategories(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/spare-part-category?${data}`)
          .then((response) => {
            content.commit("setSparePartCategories", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateSparePartCategory(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/spare-part-category`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdSparePartCategories", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateSparePartCategory(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/spare-part-category/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdSparePartCategories", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowSparePartCategory(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/spare-part-category/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteSparePartCategory(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/spare-part-category/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdSparePartCategories", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
