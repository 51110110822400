import axios from "axios";
import store from "..";

export default {
  state: {
    fault_acts: [],
    dd_fault_acts: [],
  },
  getters: {
    fault_acts: (state) => state.fault_acts,
    dd_fault_acts: (state) => state.dd_fault_acts,
  },
  mutations: {
    setFaultActs(state, fault_acts) {
      state.fault_acts = fault_acts;
    },
    setDdFaultActs(state, fault_acts) {
      state.dd_fault_acts = fault_acts ?? [];
      // fault_acts ? localStorage.setItem("dd_fault_acts", JSON.stringify(fault_acts)) : localStorage.removeItem("dd_fault_acts");
    },
  },
  actions: {
    fetchFaultActs(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fault-act?${data}`)
          .then((response) => {
            content.commit("setFaultActs", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateFaultAct(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/fault-act`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFaultActs", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateFaultAct(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/fault-act/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFaultActs", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowFaultAct(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/fault-act/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteFaultAct(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/fault-act/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdFaultActs", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCompleteFaultAct(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/fault-act/${data.id}/complete`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
