<template>
  <div class="layout-sidebar">
    <main-menu />
  </div>
</template>

<script>
import MainMenu from './menu/Menu.vue';
export default {
  components: { MainMenu },
  name: "Sidebar",
};
</script>
