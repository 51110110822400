import axios from "axios";
import store from "..";

export default {
  state: {
    consumpt_documents: [],
    dd_consumpt_documents: [],
  },
  getters: {
    consumpt_documents: (state) => state.consumpt_documents,
    dd_consumpt_documents: (state) => state.dd_consumpt_documents,
  },
  mutations: {
    setConsumptDocuments(state, consumpt_documents) {
      state.consumpt_documents = consumpt_documents;
    },
    setDdConsumptDocuments(state, consumpt_documents) {
      state.dd_consumpt_documents = consumpt_documents ?? [];
      // consumpt_documents ? localStorage.setItem("dd_consumpt_documents", JSON.stringify(consumpt_documents)) : localStorage.removeItem("dd_consumpt_documents");
    },
  },
  actions: {
    fetchConsumptDocuments(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/consumpt-document?${data}`)
          .then((response) => {
            content.commit("setConsumptDocuments", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateConsumptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/consumpt-document`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdConsumptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateConsumptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/consumpt-document/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdConsumptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowConsumptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/consumpt-document/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteConsumptDocument(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/consumpt-document/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdConsumptDocuments", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
