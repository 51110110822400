import axios from "axios";
import store from "..";

export default {
  state: {
    waybills: [],
    dd_waybills: [],
  },
  getters: {
    waybills: (state) => state.waybills,
    dd_waybills: (state) => state.dd_waybills,
  },
  mutations: {
    setWaybills(state, waybills) {
      state.waybills = waybills;
    },
    setDdWaybills(state, waybills) {
      state.dd_waybills = waybills ?? [];
      // waybills ? localStorage.setItem("dd_waybills", JSON.stringify(waybills)) : localStorage.removeItem("dd_waybills");
    },
  },
  actions: {
    fetchWaybills(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/waybill?${data}`)
          .then((response) => {
            content.commit("setWaybills", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchCreateWaybill(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${store.state.backend_url}api/master/waybill`, data)
          .then((response) => {
            resolve(response.data.data);
            content.commit("setDdWaybills", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateWaybill(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/waybill/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdWaybills", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchShowWaybill(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${store.state.backend_url}api/master/waybill/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchDeleteWaybill(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${store.state.backend_url}api/master/waybill/${data}`)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdWaybills", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
    fetchUpdateWaybillTaxirovka(content, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${store.state.backend_url}api/master/waybill-taxirovka/${data.id}`, data)
          .then((response) => {
            resolve(response.data);
            content.commit("setDdWaybills", null);
          })
          .catch((error) => {
            store.commit("setServerError", error);
            reject(error);
          });
      });
    },
  },
};
